"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encrypt = encrypt;

var _jsencrypt = _interopRequireDefault(require("jsencrypt/bin/jsencrypt.min"));

// 密钥对生成 http://web.chacuo.net/netrsakeypair
var publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD\n' + '2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ=='; // 加密

function encrypt(txt) {
  var encryptor = new _jsencrypt.default();
  encryptor.setPublicKey(publicKey); // 设置公钥

  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}