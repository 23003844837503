"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _compositionApi = require("@vue/composition-api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = (0, _compositionApi.defineComponent)({
  props: {},
  data: function data() {
    return {};
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _getCurrentInstance = (0, _compositionApi.getCurrentInstance)(),
        proxy = _getCurrentInstance.proxy;

    var showRequire = (0, _compositionApi.ref)(false); // 关闭弹框

    var cancelModal = function cancelModal() {
      showRequire.value = false;
      emit("innerModalShow", showRequire.value);
    };

    var billType = (0, _compositionApi.ref)("");
    var hasConfirmList = (0, _compositionApi.ref)("");
    var notExistReconciliationList = (0, _compositionApi.ref)("");
    var notPassLockList = (0, _compositionApi.ref)("");

    var initModal = function initModal(str) {
      hasConfirmList.value = str;
      showRequire.value = true;
    };

    return {
      hasConfirmList: hasConfirmList,
      notExistReconciliationList: notExistReconciliationList,
      notPassLockList: notPassLockList,
      cancelModal: cancelModal,
      initModal: initModal,
      showRequire: showRequire
    };
  },
  methods: {// 关闭弹框
    // cancelModal() {
    //   showRequire.value = false;
    //   emit("showRequireEmitEvent", showRequire.value); //告知父组件
    // },
  }
});

exports.default = _default;