"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.constructor");

var _store = _interopRequireDefault(require("@/store"));

var _user = require("@/api/system/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    user: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      loading: false,
      dialog: false,
      form: {
        oldPass: "",
        newPass: "",
        confirmPass: ""
      },
      rules: {
        oldPass: [{
          required: true,
          message: this.$setLang("enter_old_password"),
          trigger: "blur"
        }],
        newPass: [{
          required: true,
          // message: this.$setLang("enter_new_password"),
          validator: this.validatePass,
          trigger: "blur"
        }, {
          min: 6,
          max: 20,
          message: this.$setLang("password_length_incorrect"),
          trigger: "blur"
        }],
        confirmPass: [{
          required: true,
          validator: this.confirmPass,
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    cancel: function cancel() {
      if (this.user.pwdReset) {
        this.resetForm();
      } else {
        _store.default.dispatch("LogOut").then(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      }
    },
    validatePass: function validatePass(rule, value, callback) {
      var reg = new RegExp(/^\w{8,16}$/);

      if (value == "" || value == null) {
        callback(new Error(this.$setLang("enter_new_password")));
      } else {
        if (reg.test(value)) {
          var reg2 = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);

          if (reg2.test(value)) {
            callback();
          } else {
            callback(new Error(this.$setLang("password_length_incorrect")));
          }
        } else {
          callback(new Error(this.$setLang("password_length_incorrect")));
        }
      }
    },
    confirmPass: function confirmPass(rule, value, callback) {
      if (value) {
        if (this.form.newPass !== value) {
          callback(new Error(this.$setLang("password_different")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$setLang("enter_password_again")));
      }
    },
    doSubmit: function doSubmit() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          (0, _user.updatePass)(_this.form).then(function (res) {
            if (!res) {
              _this.resetForm();

              if (res.status === 400) {
                _this.loading = false;
              } else {
                _this.$notify({
                  title: _this.$setLang("password_reset_complete"),
                  type: "success",
                  duration: 1500
                });

                setTimeout(function () {
                  _store.default.dispatch("LogOut").then(function () {
                    location.reload(); // 为了重新实例化vue-router对象 避免bug
                  });
                }, 1500);
              }
            } else {
              _this.loading = false;

              _this.$notify({
                title: res.message,
                type: "error",
                duration: 1500
              });
            }
          }).catch(function (err) {
            _this.loading = false;
            console.log(err.response.data.message);
          });
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        oldPass: "",
        newPass: "",
        confirmPass: ""
      };
    }
  }
};
exports.default = _default;