"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _compositionApi = require("@vue/composition-api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = (0, _compositionApi.defineComponent)({
  props: {},
  data: function data() {
    return {};
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _getCurrentInstance = (0, _compositionApi.getCurrentInstance)(),
        proxy = _getCurrentInstance.proxy;

    var showRequire = (0, _compositionApi.ref)(false); // 关闭弹框

    var cancelModal = function cancelModal() {
      showRequire.value = false;
      emit("innerModalShow");
    };

    var billType = (0, _compositionApi.ref)("");
    var text = (0, _compositionApi.ref)("");
    var hasConfirmList = (0, _compositionApi.ref)("");
    var notExistReconciliationList = (0, _compositionApi.ref)("");
    var notPassLockList = (0, _compositionApi.ref)("");

    var initModal = function initModal(arrs, val) {
      text.value = "";
      var str = JSON.parse(JSON.stringify(arrs));

      if (val) {
        text.value = val;
      }

      hasConfirmList.value = ""; //   hasConfirmList.value = json;

      if (text.value === "") {
        text.value = proxy.$setLang("exception_is_unprocessed_can_not_shipping");
      } // console.log(arr, "arr");


      if (str.length > 1) {
        for (var i = 0; i < str.length; i++) {
          str[i] = "No." + str[i];
        }

        hasConfirmList.value = str.join("、") + "：" + text.value;
      } else {
        hasConfirmList.value = "No." + str[0] + "：" + text.value;
      } //   notExistReconciliationList.value = "";
      //   notPassLockList.value = "";
      //   if (data.hasConfirmList.length > 0) {
      //     hasConfirmList.value =
      //       data.hasConfirmList.join("、") +
      //       " " +
      //       proxy.$setLang("no_need_repeat_confirm");
      //   }
      //   if (data.notExistReconciliationList.length > 0) {
      //     notExistReconciliationList.value =
      //       data.notExistReconciliationList.join("、") +
      //       " " +
      //       proxy.$setLang("no_reconciliation_is_processed");
      //   }
      //   if (data.notPassLockList.length > 0) {
      //     notPassLockList.value =
      //       data.notPassLockList.join("、") +
      //       " " +
      //       proxy.$setLang("document_locked");
      //   }


      showRequire.value = true;
    };

    return {
      hasConfirmList: hasConfirmList,
      notExistReconciliationList: notExistReconciliationList,
      notPassLockList: notPassLockList,
      cancelModal: cancelModal,
      initModal: initModal,
      showRequire: showRequire,
      text: text
    };
  },
  methods: {// 关闭弹框
    // cancelModal() {
    //   showRequire.value = false;
    //   emit("showRequireEmitEvent", showRequire.value); //告知父组件
    // },
  }
});

exports.default = _default;