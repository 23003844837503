var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0 15px" }, on: { click: _vm.toggleClick } },
    [
      _c("img", {
        staticClass: "hamburger",
        class: { "is-active": !_vm.isActive },
        attrs: { src: _vm.packUp },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }