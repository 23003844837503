"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "hySearch",
  props: {
    formData: Object,
    formItemsChildren: Array,
    showSelect: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      isShow: false,
      collapseStatus: true
    };
  },
  // watch: {
  //   formData: {
  //     handler: function (params) {
  //       console.log("paramsparams", params);
  //       let keyList = Object.keys(params);
  //       keyList.forEach((item) => {
  //         this.$set(this.formDataCop, item, params[item]);
  //       });
  //       console.log("this.formDataCop", this.formDataCop);
  //       this.$forceUpdate();
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    formItems: function formItems() {
      return [{
        title: "",
        // span: 18,
        children: [].concat((0, _toConsumableArray2.default)(this.formItemsChildren), [{
          align: "center",
          // span: 16,
          itemRender: {
            name: "$buttons",
            children: [{
              props: {
                type: "reset",
                content: this.$setLang("reset"),
                icon: "e-icon-my-myrefresh",
                className: "resetClass"
              }
            }, {
              props: {
                type: "submit",
                content: this.$setLang("search"),
                icon: "e-icon-my-mysearch",
                className: "submitClass"
              }
            }]
          }
        }])
      }, {
        title: "右侧",
        // span: 6,
        children: [{
          field: "",
          title: "",
          visible: this.isShowViewMore(this.formItemsChildren),
          // span: 8,
          slots: {
            default: "viewMore"
          }
        }]
      }];
    }
  },
  methods: {
    isShowViewMore: function isShowViewMore(formItemsChildren) {
      if (!formItemsChildren || !formItemsChildren.length) {
        return false;
      } else {
        if (formItemsChildren.findIndex(function (ele) {
          return ele.folding;
        }) > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    shwoCollapse: function shwoCollapse() {
      var _this = this;

      if (this.isShow) {
        this.isShow = false;
      } else {
        this.$nextTick(function () {
          _this.isShow = true;
        });
      }
    },
    submit: function submit(_ref) {
      var data = _ref.data;
      this.$emit("submit", data);
    },
    reset: function reset(_ref2) {
      var data = _ref2.data;
      this.$refs["xForm"].reset();
      localStorage.setItem("".concat(this.$route.path, "page"), "");
      this.$emit("reset");
    },
    updata: function updata() {
      this.$forceUpdate();
    }
  }
};
exports.default = _default;