var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c(
              "div",
              {
                staticClass: "sidebar-logo-link sidebarLogoFade-enter-active",
                staticStyle: {
                  "background-size": "100%",
                  height: "95px",
                  width: "60px",
                },
                style: "background-image:url(" + _vm.LeftMenuSmall + ")",
              },
              [
                _c("div", [
                  _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: _vm.logo },
                  }),
                ]),
              ]
            )
          : _c(
              "div",
              {
                staticClass: "sidebar-logo-link sidebarLogoFade-enter-active",
                staticStyle: {
                  "background-size": "100%",
                  height: "95px",
                  width: "212px",
                },
                style: "background-image:url(" + _vm.LeftMenu + ")",
              },
              [
                _c("div", { staticClass: "sidebar-top" }, [
                  _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: _vm.logo },
                  }),
                  _vm._v(" "),
                  _c("h1", { staticClass: "sidebar-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]),
              ]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }