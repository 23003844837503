"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));

var _components = require("./components");

var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));

var _vuex = require("vuex");

var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "LayoutToOutside",
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    Theme: _ThemePicker.default
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebarOutSide: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  mounted: function mounted() {
    if (_jsCookie.default.get("theme")) {
      this.$refs.theme.theme = _jsCookie.default.get("theme");
      this.$store.dispatch("settings/changeSetting", {
        key: "theme",
        value: _jsCookie.default.get("theme")
      });
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};
exports.default = _default;