"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _selectSearch = _interopRequireDefault(require("./src/selectSearch"));

_selectSearch.default.install = function (Vue) {
  Vue.component(_selectSearch.default.name, _selectSearch.default);
};

var _default = _selectSearch.default;
exports.default = _default;