"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/layoutToOutside/index"));

var routers = [{
  component: _index.default,
  hidden: false,
  path: '/quoteToCustomerList',
  redirect: 'noredirect',
  children: [{
    path: '/quoteToCustomerList',
    hidden: false,
    meta: {
      title: '报价列表',
      key_name: 'quote_list',
      noCache: false,
      activeMenu: 'quoteToCustomer'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/quote/quoteToCustomerList'], resolve);
    }
  }, {
    path: '/quoteToCustomerDetail',
    hidden: true,
    meta: {
      title: '报价详情',
      key_name: 'edit_quote',
      noCache: false,
      activeMenu: 'quoteToCustomer'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/quote/quoteToCustomerDetail'], resolve);
    }
  }]
}, {
  component: _index.default,
  hidden: false,
  path: '/customerInvoicesList',
  redirect: 'noredirect',
  children: [{
    path: '/customerInvoicesList',
    hidden: false,
    meta: {
      title: '客户发票列表',
      key_name: 'invoice_list',
      noCache: false,
      activeMenu: 'customerInvoicesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/invoices/customerInvoicesList'], resolve);
    }
  }, {
    path: '/customerInvoicesDetail',
    hidden: true,
    meta: {
      title: '客户发票详情',
      key_name: 'invoice_detail',
      noCache: false,
      activeMenu: 'customerInvoicesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/invoices/customerInvoicesDetail'], resolve);
    }
  }]
}, {
  component: _index.default,
  hidden: false,
  path: '/promptList',
  redirect: 'noredirect',
  children: [{
    path: '/promptList',
    hidden: false,
    meta: {
      title: '催款单列表',
      key_name: 'prompt_list',
      noCache: false,
      activeMenu: 'promptDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/sendPrompt/sendPromptList'], resolve);
    }
  }, {
    path: '/promptDetail',
    hidden: true,
    meta: {
      title: '催款单详情',
      key_name: 'prompt_detail',
      noCache: false,
      activeMenu: 'promptDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/sendPrompt/sendPromptDetail'], resolve);
    }
  }]
}, {
  component: _index.default,
  hidden: false,
  path: '/customerServiceQuotesList',
  redirect: 'noredirect',
  children: [{
    path: '/customerServiceQuotesList',
    hidden: false,
    meta: {
      title: '服务报价列表',
      key_name: 'serviceQuote_list',
      noCache: false,
      activeMenu: 'customerServiceQuotesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceQuote/customerServiceQuotesList'], resolve);
    }
  }, {
    path: '/customerServiceQuotesDetail',
    hidden: true,
    meta: {
      title: '服务报价详情',
      key_name: 'serviceQuote_detail',
      noCache: false,
      activeMenu: 'customerServiceQuotesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceQuote/customerServiceQuotesDetail'], resolve);
    }
  }]
}];
var _default = routers;
exports.default = _default;