"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default2 = {
  name: 'SelectSearch',
  data: function data() {
    return {
      newValue: '',
      options2: [],
      newOptions: []
    };
  },
  props: {
    value: String,
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    keyName: {
      type: String,
      default: 'value'
    },
    labelName: {
      type: String,
      default: 'label'
    },
    valueName: {
      type: String,
      default: 'value'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    options: function options(newV) {
      this.options2 = JSON.parse(JSON.stringify(this.options));
      this.newOptions = newV;
    },
    value: function value(newV) {
      this.newValue = newV;
    }
  },
  mounted: function mounted() {
    this.newOptions = JSON.parse(JSON.stringify(this.options));
    this.options2 = JSON.parse(JSON.stringify(this.options));
    this.newValue = this.value;
  },
  methods: {
    // 搜索
    filterMethods: function filterMethods(e) {
      var valueName = this.valueName,
          labelName = this.labelName;

      if (e) {
        //val存在
        this.newOptions = this.options2.filter(function (item) {
          if (!!~item[labelName].indexOf(e) || !!~item[labelName].toUpperCase().indexOf(e.toUpperCase()) || !!~item[valueName].indexOf(e) || !!~item[valueName].toUpperCase().indexOf(e.toUpperCase())) {
            return true;
          }
        });
      } else {
        //val为空时，还原数组
        this.newOptions = this.options2;
      }
    },
    selectChange: function selectChange(e) {
      this.newValue = e;
      this.$emit('input', e);
    },
    selectClear: function selectClear() {
      this.newOptions = this.options2;
    }
  }
};
exports.default = _default2;