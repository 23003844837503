"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccountToken = getAccountToken;
exports.getCustomerToken = getCustomerToken;
exports.getSupplierToken = getSupplierToken;
exports.getToken = getToken;
exports.removeAccountToken = removeAccountToken;
exports.removeCustomerToken = removeCustomerToken;
exports.removeSupplierToken = removeSupplierToken;
exports.removeToken = removeToken;
exports.setAccountToken = setAccountToken;
exports.setCustomerToken = setCustomerToken;
exports.setSupplierToken = setSupplierToken;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _settings = _interopRequireDefault(require("@/settings"));

var TokenKey = _settings.default.TokenKey;
var customerTokenKey = 'customerTokenKey';
var supplierTokenKey = 'supplierTokenKey';
var supplierAccountKey = 'supplierAccountKey';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(TokenKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function getCustomerToken() {
  return _jsCookie.default.get(customerTokenKey);
}

function getSupplierToken() {
  return _jsCookie.default.get(supplierTokenKey);
}

function setCustomerToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(customerTokenKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(customerTokenKey, token);
}

function removeCustomerToken() {
  return _jsCookie.default.remove(customerTokenKey);
}

function setSupplierToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(supplierTokenKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(supplierTokenKey, token);
}

function removeSupplierToken() {
  return _jsCookie.default.remove(supplierTokenKey);
}

function getAccountToken() {
  return _jsCookie.default.get(supplierAccountKey);
}

function setAccountToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(supplierAccountKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(supplierAccountKey, token);
}

function removeAccountToken() {
  return _jsCookie.default.remove(supplierAccountKey);
}