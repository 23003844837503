"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var routers = [// {
//     component: null,
//     meta: {
//         icon: "inquiry-icon",
//         noCache: true,
//         title: "quote_manage",
//         activeMenu: 'quoteToCustomer'
//     },
//     name: "报价业务",
//     path: "/quoteToCustomerList",
//     redirect: "noredirect",
// },
{
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    icon: "inquiry-icon",
    noCache: true,
    title: "quote_manage",
    activeMenu: 'quoteToCustomer'
  },
  name: "询价管理",
  path: "/quoteToCustomerList",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      activeMenu: 'quoteToCustomer',
      noCache: true,
      title: "quote_list"
    },
    name: "询价列表",
    path: "/quoteToCustomerList",
    redirect: "noredirect"
  }]
}, {
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    icon: "inquiry-icon",
    noCache: true,
    title: "invoice_list",
    activeMenu: 'customerInvoicesDetail'
  },
  name: "发票管理",
  path: "/customerInvoicesList",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      activeMenu: 'customerInvoicesDetail',
      noCache: true,
      title: "invoice_list"
    },
    name: "客户发票",
    path: "/customerInvoicesList",
    redirect: "noredirect"
  }]
}, {
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    icon: "inquiry-icon",
    noCache: true,
    title: "prompt_list",
    activeMenu: 'promptDetail'
  },
  name: "催款单",
  path: "/promptList",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      activeMenu: 'promptDetail',
      noCache: true,
      title: "prompt_list"
    },
    name: "催款单",
    path: "/promptList",
    redirect: "noredirect"
  }]
}, {
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    icon: "inquiry-icon",
    noCache: true,
    title: "service_order",
    activeMenu: 'customerServiceQuotesList'
  },
  name: "服务订单",
  path: "/customerServiceQuotesList",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      activeMenu: 'customerServiceQuotesList',
      noCache: true,
      title: "service_order"
    },
    name: "服务订单",
    path: "/customerServiceQuotesList",
    redirect: "noredirect"
  }]
}];
var _default = routers;
exports.default = _default;