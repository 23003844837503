"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.get = get;
exports.getDictMap = getDictMap;

var _request = _interopRequireDefault(require("@/utils/request"));

function get(dictName) {
  var params = {
    dictName: dictName,
    page: 0,
    size: 9999
  };
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'get',
    params: params
  });
}

function getDictMap(dictName) {
  var params = {
    dictName: dictName,
    page: 0,
    size: 9999
  };
  return (0, _request.default)({
    url: 'api/dictDetail/map',
    method: 'get',
    params: params
  });
}

function add(data) {
  var dataReal = {
    'dict': data.dict,
    'dictSort': data.dictSort,
    'label': data.label,
    'value': data.value,
    'id': null
  };
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'post',
    data: dataReal
  });
}

function del(id) {
  return (0, _request.default)({
    url: 'api/dictDetail/' + id,
    method: 'delete'
  });
}

function edit(data) {
  var dataReal = {
    'dict': data.dict,
    'dictSort': data.dictSort,
    'label': data.label,
    'value': data.value,
    'id': data.id
  };
  return (0, _request.default)({
    url: 'api/dictDetail',
    method: 'put',
    data: dataReal
  });
}

var _default = {
  add: add,
  edit: edit,
  del: del
};
exports.default = _default;