"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var t = {};
t.title = 'e云智海';
t.login = {};
t.login.client_login = '用户登录';
t.login.supplier_login = '供应商登录';
t.login.welcome_login = '欢迎登陆';
t.login.please_use = '请使用e云智海管理系统账号登录';
t.login.account = '用户名';
t.login.ple_account = '请输入您的用户名';
t.login.client_name_is_no_empty = '用户名不能为空';
t.login.password = '登录密码';
t.login.ple_password = '请输入您的登录密码';
t.login.password_is_no_empty = '密码不能为空';
t.login.verification_code = '验证码';
t.login.verification_code_is_no_empty = '请输入验证码';
t.login.login = '登录';
t.login.logging_in = '登录中';
t.login.tips1 = '登录即代表您已经同意';
t.login.Privacy_Policy = '隐私政策';
t.login.language_switch = '语言切换';
t.login.hint = '提示';
t.login.tips2 = '当前登录状态已过期，请重新登录！';
t.sys_quote = '系统报价';
t.sale_order = '销售订单';
t.purchase_order = '采购订单';
t.warehouse_operation = '仓库作业';
t.receivable_bill = '应收账单';
t.payable_bill = '应付账单';
t.fixed_asset = '固定资产';
t.unconfirmed = '待确认';
t.quotation = '报价中';
t.Undelivery = '待发货';
t.Unreceipt = '待收货';
t.customer_number = '客户数';
t.bill_number = '账单数';
t.supplier_name = '供应商';
t.asset_original_value = '资产原值';
t.accumulated_depreciation = '累计折旧';
t.copyright = '版权所有：易码盛软件科技(南通)有限公司2023 ⋅ 苏ICP备2021011858号-1';
var _default = t;
exports.default = _default;