"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserInfo = exports.logOut = exports.default = void 0;

var _login = require("@/api/login");

var _auth = require("@/utils/auth");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _index = _interopRequireDefault(require("../index"));

var user = {
  state: {
    token: (0, _auth.getToken)(),
    user: {},
    roles: [],
    dynRoles: [],
    // 第一次加载菜单时用到
    loadMenus: false,
    terminalType: 1 //1:默认，2：客户，3：供应商

  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    },
    //设置当前登录端
    SET_CURRENT_TERMINAL: function SET_CURRENT_TERMINAL(state, type) {
      state.terminalType = type;
    },
    SET_DYNROLES: function SET_DYNROLES(state, dynRoles) {
      state.dynRoles = dynRoles;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        (0, _login.login)(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(function (res) {
          if (res.code != undefined) {
            reject(res);
            return;
          }

          (0, _auth.setToken)(res.token, rememberMe);
          commit('SET_TOKEN', res.token);
          setUserInfo(res.user, commit); // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

          commit('SET_LOAD_MENUS', true);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)().then(function (res) {
          setUserInfo(res, commit);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)().then(function (res) {
          logOut(commit);
          resolve();
        }).catch(function (error) {
          logOut(commit);
          reject(error);
        });
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    },
    //判断是哪一个端登录
    judgeWhichTerminal: function judgeWhichTerminal(_ref5, type) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_CURRENT_TERMINAL', type);
      });
    }
  }
};

var logOut = function logOut(commit) {
  console.log('store.getters.terminalType', _index.default.getters.terminalType);

  if (_index.default.getters.terminalType === 1) {
    commit('SET_TOKEN', '');
    commit('SET_ROLES', []);
    commit('SET_DYNROLES', []);
    (0, _auth.removeToken)();
  } else if (_index.default.getters.terminalType === 2) {
    (0, _auth.removeCustomerToken)();
  } else if (_index.default.getters.terminalType === 3) {
    (0, _auth.removeSupplierToken)();

    _jsCookie.default.remove('accountName');
  }
};

exports.logOut = logOut;

var setUserInfo = function setUserInfo(res, commit) {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
  } else {
    commit('SET_ROLES', res.roles);
    commit('SET_DYNROLES', res.dynRoles);
  }

  commit('SET_USER', res.user);
};

exports.setUserInfo = setUserInfo;
var _default = user;
exports.default = _default;