exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "205px",
	"menuBgOut": "#10183F",
	"menuHoverOut": "#28324D",
	"subMenuBgOut": "#10183F",
	"subMenuHoverOut": "#28324D",
	"sideBarWidthOut": "212px"
};