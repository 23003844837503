"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var routers = [{
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    icon: "inquiry-icon",
    noCache: true,
    title: "inquiry_manage",
    activeMenu: 'inquiryToSupplier'
  },
  name: "询价管理",
  path: "/inquiry",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      activeMenu: 'inquiryToSupplier',
      noCache: true,
      title: "inquiry_list"
    },
    name: "询价列表",
    path: "/inquiryToSupplierList",
    redirect: "noredirect"
  }]
}, {
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    title: "purchase_list",
    icon: 'purchase-icon',
    noCache: true,
    activeMenu: 'purchaseToSupplier'
  },
  name: "采购管理",
  path: "/purchase",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      noCache: true,
      activeMenu: 'purchaseToSupplier',
      title: "supplier_purchase_list"
    },
    name: "采购管理",
    path: "/purchaseToSupplierList",
    redirect: "noredirect"
  }, {
    component: null,
    meta: {
      noCache: true,
      activeMenu: 'purchaseReturnToSupplier',
      title: "purchase_return"
    },
    name: "采购退货",
    path: "/purchaseReturnToSupplierList",
    redirect: "noredirect"
  }]
}, {
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    title: "invoice_manage",
    icon: 'purchase-icon',
    noCache: true,
    activeMenu: 'receiptConfirmation'
  },
  name: "发票管理",
  path: "/receiptConfirmation",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      noCache: true,
      title: "receipt_confirmation_list"
    },
    name: "收货确认单",
    path: "/receiptConfirmationList",
    redirect: "noredirect"
  }]
}, // {
//   alwaysShow: true,
//   component: "Layout",
//   hidden: false,
//   meta: { title: "服务管理", icon: 'purchase-icon', noCache: true, activeMenu: 'serviceOrderDetail' },
//   name: "服务管理",
//   path: "/serviceOrderList",
//   redirect: "noredirect",
//   children: [{
//     component: null,
//     meta: {
//       noCache: true,
//       title: "service_order_list",
//     },
//     name: "服务订单",
//     path: "/serviceOrderList",
//     redirect: "noredirect",
//   }],
// },
{
  alwaysShow: true,
  component: "Layout",
  hidden: false,
  meta: {
    icon: "purchase-icon",
    noCache: true,
    title: "service_manage",
    activeMenu: 'serviceOrderDetail'
  },
  name: "服务管理",
  path: "/serviceOrderList",
  redirect: "noredirect",
  children: [{
    component: null,
    meta: {
      activeMenu: 'serviceOrderDetail',
      noCache: true,
      title: "service_order_list"
    },
    name: "服务订单",
    path: "/serviceOrderList",
    redirect: "noredirect"
  }]
}];
var _default = routers;
exports.default = _default;