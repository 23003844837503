"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _packUp = _interopRequireDefault(require("@/assets/images/packUp.png"));

//
//
//
//
//
//
var _default = {
  name: "Hamburger",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      packUp: _packUp.default
    };
  },
  methods: {
    toggleClick: function toggleClick() {
      this.$emit("toggleClick");
    }
  }
};
exports.default = _default;