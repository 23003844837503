"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _logo = _interopRequireDefault(require("@/assets/images/logo.png"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: "e云智海",
      logo: _logo.default
    };
  }
};
exports.default = _default;