"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Loadings = void 0;
exports.addClass = addClass;
exports.addZero = addZero;
exports.byteLength = byteLength;
exports.calculation = calculation;
exports.cleanArray = cleanArray;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.downloadFile = downloadFile;
exports.filterExtraKey = filterExtraKey;
exports.formartDict = formartDict;
exports.formatMoney = formatMoney;
exports.formatTime = formatTime;
exports.formatTimeData = formatTimeData;
exports.formatTimeDatas = formatTimeDatas;
exports.formatterParams = formatterParams;
exports.getFileName = getFileName;
exports.getFileSuffix = getFileSuffix;
exports.getQueryObject = getQueryObject;
exports.getQueryVariable = getQueryVariable;
exports.getTime = getTime;
exports.hasClass = hasClass;
exports.hasPermission = hasPermission;
exports.html2Text = html2Text;
exports.inputDiscount = inputDiscount;
exports.inputNumber = inputNumber;
exports.inputNumber2 = inputNumber2;
exports.inputNumber4 = inputNumber4;
exports.judgeIsNumberType = judgeIsNumberType;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.regEmail = regEmail;
exports.regMobile = regMobile;
exports.removeClass = removeClass;
exports.setLang = setLang;
exports.setLangByLink = setLangByLink;
exports.toggleClass = toggleClass;
exports.uniqueArr = uniqueArr;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.to-string");

var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _elementUi = require("element-ui");

var _index = _interopRequireDefault(require("../store/index"));

var _routers = _interopRequireDefault(require("@/router/routers"));

var _modulePage = require("../router/module&page");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

/**
 * Created by PanJiaChen on 16/11/18.
 */
var loading = null; //加载实例

function hasPermission(key) {
  return window.SITE_CONFIG['permissions'].indexOf(key) !== -1 || false;
}
/**
* 加载动画
*/


var Loadings = {
  //开始加载动画
  startLoading: function startLoading() {
    loading = _elementUi.Loading.service({
      lock: true,
      //是否锁定
      text: _jsCookie.default.get('language') == 'zh-CN' ? '拼命加载中...' : 'Loading...',
      //加载中需要显示的文字
      background: 'rgba(0,0,0,.5)' //背景颜色

    });
  },
  //结束加载动画
  endLoading: function endLoading() {
    loading.close();
  }
};
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */

exports.Loadings = Loadings;

function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (typeof time === 'undefined' || time === null || time === 'null') {
    return '';
  } else if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */


function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */


function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}
/**
 * @param {string} input value
 * @returns {number} output value
 */


function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;

  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }

  return s;
}
/**
 * @param {Array} actual
 * @returns {Array}
 */


function cleanArray(actual) {
  var newArray = [];

  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
}
/**
 * @param {Object} json
 * @returns {Array}
 */


function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
/**
 * @param {string} url
 * @returns {Object}
 */


function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * @param {string} val
 * @returns {string}
 */


function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */


function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== 'object') {
    target = {};
  }

  if (Array.isArray(source)) {
    return source.slice();
  }

  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];

    if ((0, _typeof2.default)(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
/**
 * @param {HTMLElement} element
 * @param {string} className
 */


function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }

  var classString = element.className;
  var nameIndex = classString.indexOf(className);

  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }

  element.className = classString;
}
/**
 * @param {string} type
 * @returns {Date}
 */


function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */


function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;

  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp; // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null; // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用

      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout; // 如果延时不存在，重新设定延时

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */


function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }

  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
/**
 * @param {Array} arr
 * @returns {Array}
 */


function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
/**
 * @returns {string}
 */


function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */


function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */


function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */


function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
} // 替换邮箱字符


function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    var str = email.split('@');
    var _s = '';

    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*';
      }
    }

    var new_email = str[0].substr(0, 3) + _s + '@' + str[1];
  }

  return new_email;
} // 替换手机字符


function regMobile(mobile) {
  if (mobile.length > 7) {
    var new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7);
  }

  return new_mobile;
} // 下载文件


function downloadFile(obj, name, suffix) {
  var url = window.URL.createObjectURL(new Blob([obj]));
  var link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  var fileName = parseTime(new Date()) + '-' + name + '.' + suffix;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
/**
 * formatter 传参字段
 * @param {*} objTemplate 模板对象
 * @param {*} obj 源对象
 * @returns 
 */


function formatterParams(objTemplate, obj) {
  if ((0, _typeof2.default)(obj) === 'object') {
    var objTemplateKeys = Object.keys(objTemplate);
    var objKeys = Object.keys(obj);

    for (var i = 0; i < objKeys.length; i++) {
      var objEle = objKeys[i];

      for (var j = 0; j < objTemplateKeys.length; j++) {
        var objTemplateEle = objTemplateKeys[j];

        if (objTemplateKeys.includes(objEle) && objTemplateEle === objEle) {
          objTemplate[objTemplateEle] = obj[objEle];
        }
      }
    }
  }

  return objTemplate;
}

function formatTimeData(date) {
  var isHasHms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  return "".concat([year, month, day].map(formatNumber).join('-'), " ").concat(isHasHms ? [hour, minute, second].map(formatNumber).join(':') : '');
}

function formatTimeDatas(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  return "".concat([year, month, day].map(formatNumber).join('-'), " ").concat([hour, minute].map(formatNumber).join(':'));
}

var formatNumber = function formatNumber(n) {
  n = n.toString();
  return n[1] ? n : "0".concat(n);
};

function filterExtraKey(obj) {
  var extraArr = ['createBy', 'createTime', 'updateBy', 'updateTime'];

  if (Object.prototype.toString(obj) == '[object Object]') {
    extraArr.forEach(function (item) {
      delete obj[item];
    });
  }

  return obj;
}
/**
 * 判断是否为数字类型
 */


function judgeIsNumberType(v) {
  var flag = true;

  if (v === null || v === '' || v === void 0) {
    flag = false;
  } else {
    if (typeof +v !== 'number') {
      flag = false;
    } else {
      if (isNaN(+v)) {
        return false;
      }
    }
  }

  return flag;
}
/**
 * 获取url参数
 */


function getQueryVariable(variable) {
  var query = window.location.href.substring(1);
  var vars = query.split(/\?|\&/);

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");

    if (pair[0] == variable) {
      return pair[1];
    }
  }

  return false;
}
/**
 * 字典formart
 * @param {*} value 
 * @param {*} dict 
 * @returns label
 */


function formartDict() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var dict = arguments.length > 1 ? arguments[1] : undefined;
  return dict && dict.map(function (item) {
    if (value !== undefined && value !== null && value.toString() === item.value) {
      return item.label;
    }
  }).filter(Boolean)[0];
}
/**
 * 国际化设置语言
 */


function setLang(key, page) {
  var language = key;

  var pageName = _routers.default.history.current.path.split('/').pop();

  if (_index.default.getters.langCommon[key]) {
    language = _index.default.getters.langCommon[key];
    return language;
  }

  if (_modulePage.pageMap[pageName]) {
    for (var i = 0; i < _modulePage.pageMap[pageName].length; i++) {
      if (_index.default.getters[_modulePage.pageMap[pageName][i]][key]) {
        language = _index.default.getters[_modulePage.pageMap[pageName][i]][key];
        return language;
      }
    }
  }

  return language; // let language
  // switch (page) {
  //   case 'base':
  //     language = store.getters.getLangBase[key] || ''
  //     break;
  //   case 'sale':
  //     language = store.getters.getLangSell[key] || ''
  //     break;
  //   case 'purchas':
  //     language = store.getters.getLangPurchase[key] || ''
  //     break;
  //   case 'finance':
  //     language = store.getters.getLangFinance[key]  || ''
  //     break;
  //   case 'depository':
  //     language = store.getters.getLangEntrepot[key]  || ''
  //     break;
  //   default:
  //     language = store.getters.langCommon[key] || ''
  //     break;
  // }
}
/**
 * 根据路由里的language设置语言
 */


function setLangByLink(to) {
  function handleStr(val) {
    if (val.indexOf("?") != -1) {
      var s = val.indexOf("?");
      var strs = val.slice(s).split("&").join("=").split("=");
      var b = strs.findIndex(function (it) {
        return it == "language";
      });
      return strs[b + 1];
    }
  }

  if (to.fullPath.indexOf("language") !== -1 && handleStr(to.fullPath)) {
    _index.default.commit("UPDATELANG", handleStr(to.fullPath)); // location.reload()

  }
}

function myIsNaN(value) {
  return typeof value === 'number' && !isNaN(value);
} //表格里输入框补零
//补0


function addZero(val, pro) {
  if (val[pro]) {
    var valList = val[pro].toString().split(".");

    if (valList.length > 0 && valList[1] !== '') {
      if (valList.length === 1) {
        val[pro] = val[pro].toString() + ".00";
      }

      if (valList.length === 2 && valList[1].length < 2) {
        val[pro] = val[pro].toString() + "0";
      }
    }
  }
}

; //金额千分位

function formatMoney(number) {
  // 转为字符串，并按照.拆分
  var arr = String(number).split('.'); // 整数部分再拆分

  var int = arr[0].split(''); // 小数部分

  var fraction = arr[1] || ''; // 返回的变量

  var r = '';
  int.reverse().forEach(function (v, i) {
    // 非第一位并且是位值是3的倍数，添加“,”
    if (i !== 0 && i % 3 === 0 && myIsNaN(v)) {
      r = v + ',' + r;
    } else {
      // 正常添加字符(这是好写法)
      r = v + r;
    }
  }); // 整数部分和小数部分拼接

  return r + (!!fraction ? '.' + fraction : '');
} // number输入框校验


function inputNumber(val, row, column) {
  val = val.replace(/(^\s*)|(\s*$)/g, "");
  var reg = /[^\d.]/g; // 只能是数字和小数点，不能是其他输入

  val = val.replace(reg, ""); // 保证第一位只能是数字，不能是点

  val = val.replace(/^\./g, ""); // 小数只能出现1位

  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 小数点后面保留2位

  val = val.replace(/^\D*(\d{0,5}(?:\.\d{0,2})?).*$/g, "$1").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
  row[column.property] = val;
}

; // number输入负数校验

function inputNumber4(val, row, column) {
  val = val.replace(/(^\s*)|(\s*$)/g, "");
  var reg = /[^-\d.]/g; // 只能是数字和小数点，不能是其他输入

  val = val.replace(reg, ""); // 保证第一位只能是数字，不能是点

  val = val.replace(/^\./g, ""); // 小数只能出现1位

  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
  val = val.replace(/^\d+$/, "");

  if (val.charAt(0) && val.charAt(0) === ".") {
    val = val.replace(".", "-0.");
  }

  if (val.charAt(0) && val.charAt(0) === "0") {
    val = val.replace("0", "");
  }

  if (val < -99999.99) {
    val = -99999.99;
  }

  row[column.property] = val;
}

;

function inputNumber2(val, row, column) {
  val = val.replace(/(^\s*)|(\s*$)/g, "");
  var reg = /[^\d.]/g; // 只能是数字和小数点，不能是其他输入

  val = val.replace(reg, ""); // 保证第一位只能是数字，不能是点

  val = val.replace(/^\./g, ""); // 小数只能出现1位

  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 小数点后面保留2位

  val = val.replace(/^\D*(\d{0,7}(?:\.\d{0,2})?).*$/g, "$1").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
  row[column.property] = val;
}

; // 折扣部分

function inputDiscount(val, row, column) {
  val = val.replace(/(^\s*)|(\s*$)/g, "");
  var reg = /[^\d.]/g; // 只能是数字和小数点，不能是其他输入

  val = val.replace(reg, ""); // 保证第一位只能是数字，不能是点

  val = val.replace(/^\./g, ""); // 小数只能出现1位

  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 小数点后面保留2位

  val = val.replace(/^\D*(\d{0,2}(?:\.\d{0,2})?).*$/g, '$1').replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3');
  row[column.property] = val;
}

; //js 小数相加减精确结果

function calculation(type, num1, num2) {
  var temp1, temp2, a;

  try {
    // 获取temp1小数点后的长度
    temp1 = num1.toString().split(".")[1].length;
  } catch (e) {
    temp1 = 0;
  }

  try {
    // 获取temp2小数点后的长度
    temp2 = num2.toString().split(".")[1].length;
  } catch (e) {
    temp2 = 0;
  } // Math.max(temp1, temp2) 为了获取temp1和temp2两个值中较大的一个
  // Math.pow(a,b) 表示 a 的 b 次方


  a = Math.pow(10, Math.max(temp1, temp2)); // 计算的方式是先将所有的小数乘为整数，待加减运算执行完之后再除去对应的 a 的值，将其变为小数输出
  // 先判断执行的方式是否是加法，不是的话则执行减法运算

  return type == "add" ? (num1 * a + num2 * a) / a : (num1 * a - num2 * a) / a;
} // 获取文件后缀


function getFileSuffix(val) {
  var strList = val.split('.');
  var str = strList[strList.length - 1];
  var strReal = '';

  if (str == 'jpg' || str == 'png' || str == 'git' || str == 'jpeg') {
    strReal = 'image';
  } else if (str == 'doc' || str == 'docx' || str == 'rtf') {
    strReal = 'world';
  } else if (str == 'pdf') {
    strReal = 'pdf';
  } else if (str == 'xls' || str == 'xlsx') {
    strReal = 'excel';
  }

  return strReal;
} // 获取文件名称


function getFileName(val) {
  var strList = val.split('.');
  var str = strList[strList.length - 2];
  return str;
}