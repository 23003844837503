"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.replace");

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Breadcrumb = _interopRequireDefault(require("./Breadcrumb"));

var _newFace = _interopRequireDefault(require("../../assets/images/newFace.png"));

var _hambergerOut = _interopRequireDefault(require("@/components/Hamburger/hambergerOut.vue"));

var _Doc = _interopRequireDefault(require("@/components/Doc"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

var _SizeSelect = _interopRequireDefault(require("@/components/SizeSelect"));

var _langSelect = _interopRequireDefault(require("@/components/langSelect"));

var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));

var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import face from "../../assets/images/face.png";
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _hambergerOut.default,
    Screenfull: _Screenfull.default,
    SizeSelect: _SizeSelect.default,
    Search: _HeaderSearch.default,
    Doc: _Doc.default,
    LangSelect: _langSelect.default
  },
  data: function data() {
    return {
      face: _newFace.default,
      Avatar: _avatar.default,
      dialogVisible: false
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar", "device", "user", "baseApi"])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val
        });
      }
    },
    sethint: function sethint() {
      return this.$setLang("good_day").replace("{0}", "".concat(this.greetings));
    },
    greetings: function greetings() {
      var date = new Date();
      var dateKey = "";

      switch (date.getDay()) {
        case 1:
          dateKey = this.$setLang("Monday");
          break;

        case 2:
          dateKey = this.$setLang("Tuesday");
          break;

        case 3:
          dateKey = this.$setLang("Wednesday");
          break;

        case 4:
          dateKey = this.$setLang("Thursday");
          break;

        case 5:
          dateKey = this.$setLang("Friday");
          break;

        case 6:
          dateKey = this.$setLang("Saturday");
          break;

        case 7:
          dateKey = this.$setLang("Sunday");
          break;

        default:
          break;
      }

      return dateKey;
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    open: function open() {
      var _this = this;

      this.$confirm(this.$setLang("log_out"), this.$setLang("hint"), {
        confirmButtonText: this.$setLang("confirm"),
        cancelButtonText: this.$setLang("cancel"),
        type: "warning"
      }).then(function () {
        _this.logout();
      });
    },
    logout: function logout() {
      var _this2 = this;

      var view = this.$route;
      this.$store.dispatch("tagsView/delAllViews").then(function (_ref) {
        var visitedViews = _ref.visitedViews;
        // this.toLastView(visitedViews, view);
        setTimeout(function () {
          if (_this2.$store.state.user.terminalType === 3) {
            sessionStorage.removeItem("OnlyInquiry");

            _this2.$store.dispatch("LogOut").then(function () {
              localStorage.removeItem("accountName");
              localStorage.removeItem("password");

              _this2.$router.replace({
                path: "/supplierLogin"
              });
            });
          } else {
            _this2.$store.dispatch("LogOut").then(function () {
              _this2.$router.replace({
                path: "/customerLogin"
              });
            });
          }
        }, 100);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var _this3 = this;

      // return
      var latestView = visitedViews.slice(-1)[0];

      if (latestView) {
        this.$router.push(latestView);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({
            path: "/redirect" + view.fullPath
          });
        } else {
          this.$router.push("/");
        }
      }

      setTimeout(function () {
        _this3.$store.dispatch("LogOut").then(function () {
          location.reload();
        });
      }, 100);
    }
  }
};
exports.default = _default;