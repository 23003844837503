var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        clearable: "",
        value: _vm.newValue,
        disabled: _vm.disabled,
        placeholder: _vm.$setLang("no_choose"),
        "filter-method": _vm.filterMethods,
      },
      on: { change: _vm.selectChange, clear: _vm.selectClear },
    },
    _vm._l(_vm.newOptions, function (item) {
      return _c("el-option", {
        key: item[_vm.keyName],
        attrs: { label: item[_vm.labelName], value: item[_vm.valueName] },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }