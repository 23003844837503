"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.filterAsyncRouter = exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _routers = require("@/router/routers");

var _index = _interopRequireDefault(require("@/layout/index"));

var _ParentView = _interopRequireDefault(require("@/components/ParentView"));

var _index2 = _interopRequireDefault(require("../index"));

var permission = {
  state: {
    routers: _routers.constantRouterMap,
    addRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = _routers.constantRouterMap.concat(routers);
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routers) {
      state.sidebarRouters = _routers.constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    },
    SetSidebarRouters: function SetSidebarRouters(_ref2, sidebarRouter) {
      var commit = _ref2.commit;
      commit('SET_SIDEBAR_ROUTERS', sidebarRouter);
    }
  }
};

var filterAsyncRouter = function filterAsyncRouter(routers) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(function (router) {
    if (type && router.children) {
      router.children = filterChildren(router.children);
    }

    if (_index2.default.getters.user && router.path === 'buyer-order-list') {
      var roles = _index2.default.getters.user.roles;

      for (var index = 0; index < roles.length; index++) {// 目前就加上了管理员. 销售主管id:30/bym业务员id:34/销售助理id:31未加 如果添添加了新的角色需要加上if条件吗
        // if (roles[index].id === 1 || roles[index].id === 3) {
        //   router.hidden = true
        //   return
        // }
      }
    }

    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = _index.default;
      } else if (router.component === 'ParentView') {
        router.component = _ParentView.default;
      } else {
        var component = router.component;
        router.component = loadView(component);
      }
    }

    if (router.children != null && router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, router, type);
    } else {
      delete router['children'];
      delete router['redirect'];
    }

    return true;
  });
};

exports.filterAsyncRouter = filterAsyncRouter;

function filterChildren(childrenMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(function (c) {
          c.path = el.path + '/' + c.path;

          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }

          children.push(c);
        });
        return;
      }
    }

    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;
    }

    children = children.concat(el);
  });
  return children;
}

var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};

exports.loadView = loadView;
var _default = permission;
exports.default = _default;