"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AppMain",
  computed: {
    cachedViews: function cachedViews() {
      console.log("this.$store.state.tagsView.cachedViews", this.$store.state.tagsView.cachedViews);
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      console.log("this.$route.path", this.$route.path);
      return this.$route.path;
    }
  }
};
exports.default = _default;