"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/core-js/modules/es6.array.iterator.js");

require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/core-js/modules/es6.promise.js");

require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/core-js/modules/es6.object.assign.js");

require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

var _compositionApi = _interopRequireDefault(require("@vue/composition-api"));

var _vueQuillEditor = _interopRequireDefault(require("vue-quill-editor"));

require("quill/dist/quill.core.css");

require("quill/dist/quill.snow.css");

require("quill/dist/quill.bubble.css");

var _vxeTable = _interopRequireDefault(require("vxe-table"));

var _mavonEditor = _interopRequireDefault(require("mavon-editor"));

var _vxeTablePluginElement = _interopRequireDefault(require("vxe-table-plugin-element"));

var _Dict = _interopRequireDefault(require("./components/Dict"));

var _permission = _interopRequireDefault(require("@/utils/permission"));

var _dynpermission = _interopRequireDefault(require("@/utils/dynpermission"));

var _Permission = _interopRequireDefault(require("./components/Permission"));

require("./assets/styles/element-variables.scss");

require("./assets/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _i18n2 = _interopRequireDefault(require("@/i18n"));

var _store = _interopRequireDefault(require("./store"));

var _routers = _interopRequireDefault(require("./router/routers"));

var _request = _interopRequireDefault(require("@/utils/request"));

var _search = _interopRequireDefault(require("./components/collapse/search"));

var _utils = require("@/utils");

var _selectSearch = _interopRequireDefault(require("@/components/selectSearch"));

require("default-passive-events");

require("@/utils/eventBus");

var _debounce = _interopRequireDefault(require("lodash/debounce"));

require("./assets/icons");

require("./router/index");

var _directives = _interopRequireDefault(require("./directives"));

var _commonmodal = _interopRequireDefault(require("./components/CommonModal/commonmodal.js"));

var _commonmodal2 = _interopRequireDefault(require("./components/annotationCommonModal/commonmodal.js"));

var _receiveCommonModal = _interopRequireDefault(require("./components/receiveCommonModal/receiveCommonModal.js"));

var _shipmentCommonModal = _interopRequireDefault(require("./components/shipmentCommonModal/shipmentCommonModal.js"));

var _baseCommonModal = _interopRequireDefault(require("./components/baseCommonModal/baseCommonModal.js"));

var _virtualTree = _interopRequireDefault(require("@sangtian152/virtual-tree"));

require("@sangtian152/virtual-tree/lib/vl-tree.css");

// 引入 composition api
// const VueCompositionAPI = window.vueCompositionApi
_vue.default.use(_vueQuillEditor.default);

_vue.default.use(_compositionApi.default); //vxe-table 


_vue.default.prototype.$debounce = function (fun) {
  return (0, _debounce.default)(fun, 1000, {
    leading: true,
    trailing: false
  });
}; // require('./mock.js')


_vue.default.component('collapse-search', _search.default);

_vue.default.use(_commonmodal.default);

_vue.default.use(_commonmodal2.default);

_vue.default.use(_receiveCommonModal.default);

_vue.default.use(_shipmentCommonModal.default);

_vue.default.use(_baseCommonModal.default);

_vue.default.use(_virtualTree.default);

_vue.default.use(_directives.default);

_vue.default.use(_permission.default);

_vue.default.use(_dynpermission.default); // Vue.use(VueHighlightJS)


_vue.default.use(_mavonEditor.default);

_vue.default.use(_Permission.default);

_vue.default.use(_Dict.default);

_vue.default.use(_selectSearch.default);

_vue.default.prototype.$formatMoney = _utils.formatMoney;
_vue.default.prototype.$hasPermission = _utils.hasPermission;
_vue.default.prototype.$Loadings = _utils.Loadings;
_vue.default.prototype.$setLang = _utils.setLang;
_vue.default.prototype.$formartDict = _utils.formartDict;
_vue.default.prototype.$inputNumber = _utils.inputNumber;
_vue.default.prototype.$inputNumber2 = _utils.inputNumber2;
_vue.default.prototype.$inputNumber4 = _utils.inputNumber4;
_vue.default.prototype.$getFileSuffix = _utils.getFileSuffix;
_vue.default.prototype.$getFileName = _utils.getFileName;
_vue.default.prototype.$inputDiscount = _utils.inputDiscount;
_vue.default.prototype.$http = _request.default;
_vue.default.prototype.$XSaveFile = _vxeTable.default.saveFile;
_vue.default.prototype.$XReadFile = _vxeTable.default.readFile;
_vue.default.prototype.$XModal = _vxeTable.default.modal;

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'mini',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n2.default.t(key, value);
  }
});

_vue.default.use(_vxeTable.default, {
  size: _jsCookie.default.get('size') || 'mini',
  i18n: function i18n(key, value) {
    return _i18n2.default.t(key, value);
  }
});

_vue.default.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function inserted(el) {
    // 聚焦元素
    el.focus(); // element-ui

    el.children[0].focus(); // el.children[0].select()
    // 元素有变化，如show或者父元素变化可以加延时或判断
    // setTimeout(_ => {
    //   el.children[0].focus()
    // }, 0)
  }
});

_vue.default.directive('select', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function inserted(el) {
    // 聚焦元素
    el.focus(); // element-ui

    el.children[0].focus();
    el.children[0].select();
  }
});

_vue.default.directive('loadmore', {
  bind: function bind(el, binding) {
    var SELECTWARP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWARP_DOM.addEventListener('scroll', function () {
      var CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight + 40;

      if (CONDITION) {
        binding.value();
      }
    });
  }
});

_vxeTable.default.setup({
  // 对组件内置的提示语进行国际化翻译
  i18n: function i18n(key, args) {
    return _i18n2.default.t(key, args);
  },
  table: {
    border: 'inner',
    showOverflow: true,
    size: 'mini'
  },
  toolbar: {
    size: 'mini'
  },
  button: {
    size: 'mini'
  },
  form: {
    size: 'mini'
  },
  grid: {
    size: 'mini'
  }
});

_vxeTable.default.use(_vxeTablePluginElement.default);

_vue.default.config.productionTip = false;
_vue.default.config.devtools = true;
console.log(_routers.default, 'router');
new _vue.default({
  el: '#app',
  i18n: _i18n2.default,
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});