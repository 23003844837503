"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.editUser = editUser;
exports.resetPassword = resetPassword;
exports.updateEmail = updateEmail;
exports.updatePass = updatePass;

var _request = _interopRequireDefault(require("@/utils/request"));

var _rsaEncrypt = require("@/utils/rsaEncrypt");

function add(data) {
  return (0, _request.default)({
    url: 'api/users',
    method: 'post',
    data: data
  });
}

function resetPassword(data) {
  return (0, _request.default)({
    url: 'api/users/resetPass',
    method: 'post',
    data: data
  });
}

function del(ids) {
  return (0, _request.default)({
    url: 'api/users',
    method: 'delete',
    data: ids
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'api/users',
    method: 'put',
    data: data
  });
}

function editUser(data) {
  return (0, _request.default)({
    url: 'api/users/center',
    method: 'put',
    data: data
  });
}

function updatePass(user) {
  var data = {
    oldPass: (0, _rsaEncrypt.encrypt)(user.oldPass),
    newPass: (0, _rsaEncrypt.encrypt)(user.newPass)
  };
  return (0, _request.default)({
    url: 'api/users/updatePass/',
    method: 'post',
    data: data
  });
}

function updateEmail(form) {
  var data = {
    password: (0, _rsaEncrypt.encrypt)(form.pass),
    email: form.email
  };
  return (0, _request.default)({
    url: 'api/users/updateEmail/' + form.code,
    method: 'post',
    data: data
  });
}

var _default = {
  add: add,
  edit: edit,
  del: del,
  resetPassword: resetPassword
};
exports.default = _default;