"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/layoutToOutside/index"));

var routers = [{
  component: _index.default,
  hidden: false,
  path: '/inquiryToSupplierList',
  redirect: 'noredirect',
  children: [{
    path: '/inquiryToSupplierList',
    hidden: false,
    meta: {
      title: '供应商询价列表',
      key_name: 'inquiry_list',
      noCache: false,
      activeMenu: 'inquiryToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/inquiry/inquiryToSupplierList'], resolve);
    }
  }, {
    path: '/inquiryToSupplierDetail',
    hidden: true,
    meta: {
      title: '供应商询价详情',
      key_name: 'inquiry_details',
      noCache: false,
      activeMenu: 'inquiryToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/inquiry/inquiryToSupplierDetail'], resolve);
    }
  }]
}, {
  component: _index.default,
  meta: {
    title: "采购管理",
    icon: "international",
    noCache: true
  },
  name: "采购管理",
  hidden: false,
  alwaysShow: true,
  path: '/purchaseToSupplierList',
  redirect: 'noredirect',
  children: [{
    path: '/purchaseToSupplierList',
    hidden: false,
    children: [],
    meta: {
      title: '供应商采购列表',
      key_name: 'supplier_purchase_list',
      icon: 'purchase-icon',
      noCache: false,
      activeMenu: 'purchaseToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchase/purchaseToSupplierList'], resolve);
    }
  }, {
    path: '/purchaseToSupplierDetail',
    hidden: true,
    meta: {
      title: '供应商采购详情',
      key_name: 'purchase_details',
      noCache: false,
      activeMenu: 'purchaseToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchase/purchaseToSupplierDetail'], resolve);
    }
  }, {
    path: '/purchaseReturnToSupplierList',
    hidden: false,
    meta: {
      title: '供应商采购退货列表',
      key_name: 'purchase_return',
      noCache: false,
      activeMenu: 'purchaseReturnToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchaseReturn/purchaseReturnToSupplierList'], resolve);
    }
  }, {
    path: '/purchaseReturnToSupplierDetail',
    hidden: true,
    meta: {
      title: '供应商采购退货详情',
      key_name: 'purchase_return_detail',
      noCache: false,
      activeMenu: 'purchaseReturnToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchaseReturn/purchaseReturnToSupplierDetail'], resolve);
    }
  }]
}, {
  component: _index.default,
  hidden: false,
  path: '/receiptConfirmationList',
  redirect: 'noredirect',
  children: [{
    path: '/receiptConfirmationList',
    hidden: false,
    meta: {
      title: '收货确认单',
      key_name: 'receipt_confirmation_list',
      noCache: false,
      activeMenu: 'receiptConfirmation'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/receiptConfirmation/receiptConfirmationList'], resolve);
    }
  }, {
    path: '/receiptConfirmationDetail',
    hidden: false,
    meta: {
      title: '收货确认单详情',
      key_name: 'receipt_confirmation_detail',
      noCache: false,
      activeMenu: 'receiptConfirmation'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/receiptConfirmation/receiptConfirmationDetail'], resolve);
    }
  }]
}, {
  component: _index.default,
  hidden: false,
  path: '/serviceOrderList',
  redirect: 'noredirect',
  children: [{
    path: '/serviceOrderList',
    hidden: false,
    meta: {
      title: '服务订单',
      key_name: 'service_order_list',
      noCache: false,
      activeMenu: 'serviceOrderDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceOrder/serviceOrderList'], resolve);
    }
  }, {
    path: '/serviceOrderDetail',
    hidden: false,
    meta: {
      title: '服务订单详情',
      key_name: 'service_order_details',
      noCache: false,
      activeMenu: 'serviceOrderDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceOrder/serviceOrderDetail'], resolve);
    }
  }]
}];
var _default = routers;
exports.default = _default;