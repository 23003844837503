"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var t = {};
t.title = 'e-IMSP';
t.login = {};
t.login.client_login = 'Client Login';
t.login.supplier_login = 'Supplier Login';
t.login.welcome_login = 'Welcome Login';
t.login.please_use = 'Please Login using the e-IMSP management system account';
t.login.account = 'User Name';
t.login.ple_account = 'Please enter your user name';
t.login.client_name_is_no_empty = 'input account';
t.login.password = 'Login Password';
t.login.ple_password = 'Please enter your login password';
t.login.password_is_no_empty = 'input password';
t.login.verification_code = 'verification code';
t.login.verification_code_is_no_empty = 'input verification code';
t.login.login = 'Login';
t.login.logging_in = 'logging in';
t.login.tips1 = 'By logging in, you agree';
t.login.Privacy_Policy = 'Privacy&Policy';
t.login.language_switch = 'languageSwitch';
t.login.hint = 'hint';
t.login.tips2 = 'The current login status has expired，Please log in again！';
t.sys_quote = 'System Quotation';
t.sale_order = 'Sale Order';
t.purchase_order = 'Purchase Order';
t.warehouse_operation = 'Warehouse Operation';
t.receivable_bill = 'Billing Receivable';
t.payable_bill = 'Billing Payable';
t.fixed_asset = 'Fixed Asset';
t.unconfirmed = 'Unconfirmed';
t.quotation = 'Quoting';
t.Undelivery = 'Undelivered';
t.Unreceipt = 'Unreceipt';
t.customer_number = 'Cust.Qty';
t.bill_number = 'Bill';
t.supplier_name = 'Supplier';
t.asset_original_value = 'Assets';
t.accumulated_depreciation = 'DEPN';
t.copyright = 'Copyright 2023 Imarshi Software Technology (Nantong) Co., LTD. Su ICP 2021011858-1';
var _default = t;
exports.default = _default;