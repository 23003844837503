"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = require("@/utils/request");

var quote = {
  state: {
    basicData: {},
    customerCurrency: {
      customerCurrencyCode: '',
      customerExchangeRate: '',
      customerAnnualRebate: '',
      commissionRate: '',
      customerPriceAdjustmentRate: '',
      fixedExchangeRate: ''
    },
    supplierCurrency: {
      annualReward: '',
      currencyCode: '',
      invoiceTaxRate: '',
      needInvoice: false
    },
    customerCode: '',
    supplierCode: '',
    orderNo: '',
    orderNoVis: '',
    longestDateDeliver: 0,
    quoteBusinessFromType: '',
    isAddLeave: false,
    isRealShow: false,
    proTotalAmount: null,
    orderTotalAmount: 0,
    finacialCurrency: "",
    needInvoice: false,
    finacialExchange: '',
    finacialLockTag: false,
    finacialLockName: "",
    originalCustomerCurrencyCode: "",
    originalCustomerExchangeRate: '',
    viewSupplierGoods: false,
    viewSupplierGoods2: false
  },
  mutations: {
    GETCUSTOMERCURRENCY: function GETCUSTOMERCURRENCY(state, poyfill) {
      state.customerCurrency = poyfill;
    },
    GETCUSTOMERCODE: function GETCUSTOMERCODE(state, poyfill) {
      state.customerCode = poyfill;
    },
    GETLONGESTDATEDELIVER: function GETLONGESTDATEDELIVER(state, poyfill) {
      state.longestDateDeliver = poyfill;
    },
    GETQUOTEBASICDATA: function GETQUOTEBASICDATA(state, poyfill) {
      state.basicData = poyfill;
    },
    GETQUOTEFORMTYPE: function GETQUOTEFORMTYPE(state, poyfill) {
      state.quoteBusinessFromType = poyfill;
    },
    GETSUPPLIERCURRENCY: function GETSUPPLIERCURRENCY(state, poyfill) {
      state.supplierCurrency = poyfill;
    },
    GETSUPPLIERCODE: function GETSUPPLIERCODE(state, poyfill) {
      state.supplierCode = poyfill;
    },
    SUPPLIERORDERNO: function SUPPLIERORDERNO(state, poyfill) {
      state.orderNo = poyfill;
    },
    ORDERNOVISIBLE: function ORDERNOVISIBLE(state, poyfill) {
      state.orderNoVis = poyfill;
    },
    SETISADDLEAVE: function SETISADDLEAVE(state, poyfill) {
      state.isAddLeave = poyfill;
    },
    SETREALSHOW: function SETREALSHOW(state, poyfill) {
      state.isRealShow = poyfill;
    },
    SETPROTOTAL: function SETPROTOTAL(state, poyfill) {
      state.proTotalAmount = poyfill;
    },
    SETORDTOTAL: function SETORDTOTAL(state, poyfill) {
      state.orderTotalAmount = poyfill;
    },
    SETCURRENCYFINACIAL: function SETCURRENCYFINACIAL(state, poyfill) {
      state.finacialCurrency = poyfill;
    },
    SETNEEDINVOICE: function SETNEEDINVOICE(state, poyfill) {
      state.needInvoice = poyfill;
    },
    SETEXCHANGEFINACIAL: function SETEXCHANGEFINACIAL(state, poyfill) {
      state.finacialExchange = poyfill;
    },
    SETFINACIALLOCKTAG: function SETFINACIALLOCKTAG(state, poyfill) {
      state.finacialLockTag = poyfill;
    },
    SETFINACIALLOCKNAME: function SETFINACIALLOCKNAME(state, poyfill) {
      state.finacialLockName = poyfill;
    },
    SETORIGINALCURRENCYCODE: function SETORIGINALCURRENCYCODE(state, poyfill) {
      state.originalCustomerCurrencyCode = poyfill;
    },
    SETORIGINALEXCHANGERATE: function SETORIGINALEXCHANGERATE(state, poyfill) {
      state.originalCustomerExchangeRate = poyfill;
    },
    VIEWSUPPLIERGOODS: function VIEWSUPPLIERGOODS(state, poyfill) {
      state.viewSupplierGoods = poyfill;
    },
    VIEWSUPPLIERGOODS2: function VIEWSUPPLIERGOODS2(state, poyfill) {
      state.viewSupplierGoods2 = poyfill;
    }
  },
  actions: {}
};
var _default = quote;
exports.default = _default;