"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _compositionApi = require("@vue/composition-api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = (0, _compositionApi.defineComponent)({
  props: {},
  data: function data() {
    return {};
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _getCurrentInstance = (0, _compositionApi.getCurrentInstance)(),
        proxy = _getCurrentInstance.proxy;

    var showRequire = (0, _compositionApi.ref)(false); // 关闭弹框

    var cancelModal = function cancelModal() {
      showRequire.value = false;
      emit("innerModalShow", showRequire.value);
    };

    var billType = (0, _compositionApi.ref)("");
    var hasConfirmList = (0, _compositionApi.ref)([]);
    var notExistReconciliationList = (0, _compositionApi.ref)("");
    var notPassLockList = (0, _compositionApi.ref)("");

    var initModal = function initModal(json) {
      hasConfirmList.value = []; //   hasConfirmList.value = json;

      if (json.length) {
        // json.forEach((el) => {
        //   tet += "No" + el.serialNo + "-" + el.saleCode;
        // });
        // console.log(json, "json");
        var arra = [];
        json.forEach(function (ele) {
          var noAll = "";
          ele.receiveErrorList.forEach(function (item) {
            noAll += "No." + item.showSerialNo + "、";
          });
          arra.push(noAll.slice(0, -1) + "：" + ele.receiveErrorList[0].errorMessage);
        });
        console.log(arra, "555"); // console.log(arr, "arr");

        hasConfirmList.value = arra;
      } //   notExistReconciliationList.value = "";
      //   notPassLockList.value = "";
      //   if (data.hasConfirmList.length > 0) {
      //     hasConfirmList.value =
      //       data.hasConfirmList.join("、") +
      //       " " +
      //       proxy.$setLang("no_need_repeat_confirm");
      //   }
      //   if (data.notExistReconciliationList.length > 0) {
      //     notExistReconciliationList.value =
      //       data.notExistReconciliationList.join("、") +
      //       " " +
      //       proxy.$setLang("no_reconciliation_is_processed");
      //   }
      //   if (data.notPassLockList.length > 0) {
      //     notPassLockList.value =
      //       data.notPassLockList.join("、") +
      //       " " +
      //       proxy.$setLang("document_locked");
      //   }


      showRequire.value = true;
    };

    return {
      hasConfirmList: hasConfirmList,
      notExistReconciliationList: notExistReconciliationList,
      notPassLockList: notPassLockList,
      cancelModal: cancelModal,
      initModal: initModal,
      showRequire: showRequire
    };
  },
  methods: {// 关闭弹框
    // cancelModal() {
    //   showRequire.value = false;
    //   emit("showRequireEmitEvent", showRequire.value); //告知父组件
    // },
  }
});

exports.default = _default;