"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _index = _interopRequireDefault(require("../layout/index"));

var _index2 = _interopRequireDefault(require("../layoutToOutside/index"));

_vue.default.use(_vueRouter.default);

var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      key_name: 'home',
      icon: 'index',
      affix: true,
      noCache: true,
      activeMenu: 'home'
    }
  }]
}, // {
//   path: '/schedulePlan',
//   component: Layout,
//   redirect: 'noredirect',
//   children: [
//     {
//       path: 'index',
//       component: (resolve) => require(['@/views/schedulePlanManagement/index'], resolve),
//       name: 'schedulePlan',
//       meta: { title: '船期计划', key_name: 'home', icon: 'scheduleplan', affix: true, noCache: true, activeMenu: 'schedulePlan' }
//     }
//   ]
// },
{
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}, {
  component: _index.default,
  hidden: true,
  path: '/customer',
  redirect: 'noredirect',
  children: [{
    path: 'addCustomer',
    component: function component(resolve) {
      return require(['@/views/customerManage/customerManagement/details'], resolve);
    },
    name: '新增客户',
    meta: {
      title: '新增客户'
    }
  }]
}, {
  component: _index.default,
  hidden: true,
  path: '/saleGoods',
  redirect: 'noredirect',
  children: [{
    path: 'addSaleGoods',
    component: function component(resolve) {
      return require(['@/views/goodsManagement/saleGoods/details'], resolve);
    },
    name: '新增商品',
    meta: {
      title: '新增商品'
    }
  }]
}, {
  path: '/customerLogin',
  hidden: true,
  meta: {
    title: '客户登录',
    noCache: false
  },
  component: function component(resolve) {
    return require(['@/views/toOutside/customerLogin'], resolve);
  }
}, {
  component: _index2.default,
  hidden: true,
  path: '/quoteToCustomerList',
  redirect: 'noredirect',
  children: [{
    path: '/quoteToCustomerList',
    hidden: true,
    meta: {
      title: '客户报价列表',
      key_name: 'quote_list',
      noCache: false,
      activeMenu: 'quoteToCustomer'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/quote/quoteToCustomerList'], resolve);
    }
  }, {
    path: '/quoteToCustomerDetail',
    hidden: true,
    meta: {
      title: '客户报价详情',
      key_name: 'edit_quote',
      noCache: false,
      activeMenu: 'quoteToCustomer'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/quote/quoteToCustomerDetail'], resolve);
    }
  }, {
    path: '/customerInvoicesList',
    hidden: false,
    meta: {
      title: '客户发票列表',
      key_name: 'invoice_list',
      noCache: false,
      activeMenu: 'customerInvoicesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/invoices/customerInvoicesList'], resolve);
    }
  }, {
    path: '/customerInvoicesDetail',
    hidden: true,
    meta: {
      title: '客户发票详情',
      key_name: 'invoice_detail',
      noCache: false,
      activeMenu: 'customerInvoicesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/invoices/customerInvoicesDetail'], resolve);
    }
  }, {
    path: '/customerServiceQuotesList',
    hidden: false,
    meta: {
      title: '服务报价列表',
      key_name: 'serviceQuote_list',
      noCache: false,
      activeMenu: 'customerServiceQuotesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceQuote/customerServiceQuotesList'], resolve);
    }
  }, {
    path: '/customerServiceQuotesDetail',
    hidden: true,
    meta: {
      title: '服务报价详情',
      key_name: 'serviceQuote_detail',
      noCache: false,
      activeMenu: 'customerServiceQuotesDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceQuote/customerServiceQuotesDetail'], resolve);
    }
  }, {
    path: '/promptList',
    hidden: false,
    meta: {
      title: '催款单列表',
      key_name: 'prompt_list',
      noCache: false,
      activeMenu: 'promptDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/sendPrompt/sendPromptList'], resolve);
    }
  }, {
    path: '/promptDetail',
    hidden: true,
    meta: {
      title: '催款单详情',
      key_name: 'prompt_detail',
      noCache: false,
      activeMenu: 'promptDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/sendPrompt/sendPromptDetail'], resolve);
    }
  }]
}, // {
//   component: LayoutToOutside,
//   hidden: true,
//   path: '/customerInvoicesList',
//   redirect: 'noredirect',
//   children: [
//   ]
// },
{
  path: '/supplierLogin',
  hidden: true,
  meta: {
    title: '供应商登录',
    noCache: false
  },
  component: function component(resolve) {
    return require(['@/views/toOutside/supplierLogin'], resolve);
  }
}, {
  component: _index2.default,
  hidden: true,
  path: '/inquiryToSupplierList',
  redirect: 'noredirect',
  children: [{
    path: '/inquiryToSupplierList',
    hidden: true,
    meta: {
      title: '供应商询价列表',
      key_name: 'inquiry_list',
      noCache: false,
      activeMenu: 'inquiryToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/inquiry/inquiryToSupplierList'], resolve);
    }
  }, {
    path: '/inquiryToSupplierDetail',
    hidden: true,
    meta: {
      title: '供应商询价详情',
      key_name: 'inquiry_details',
      noCache: false,
      activeMenu: 'inquiryToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/inquiry/inquiryToSupplierDetail'], resolve);
    }
  }, {
    path: '/purchaseToSupplierList',
    hidden: true,
    meta: {
      title: '供应商采购列表',
      key_name: 'supplier_purchase_list',
      icon: 'purchase-icon',
      noCache: false,
      activeMenu: 'purchaseToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchase/purchaseToSupplierList'], resolve);
    }
  }, {
    path: '/purchaseToSupplierDetail',
    hidden: true,
    meta: {
      title: '供应商采购详情',
      key_name: 'purchase_details',
      noCache: false,
      activeMenu: 'purchaseToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchase/purchaseToSupplierDetail'], resolve);
    }
  }, {
    path: '/purchaseReturnToSupplierList',
    hidden: true,
    meta: {
      title: '供应商采购退货列表',
      key_name: 'purchase_return',
      noCache: false,
      activeMenu: 'purchaseReturnToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchaseReturn/purchaseReturnToSupplierList'], resolve);
    }
  }, {
    path: '/purchaseReturnToSupplierDetail',
    hidden: true,
    meta: {
      title: '供应商采购退货详情',
      key_name: 'purchase_return_detail',
      noCache: false,
      activeMenu: 'purchaseReturnToSupplier'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/purchaseReturn/purchaseReturnToSupplierDetail'], resolve);
    }
  }, {
    path: '/receiptConfirmationList',
    hidden: true,
    meta: {
      title: '收货确认单',
      key_name: 'receipt_confirmation_list',
      noCache: false,
      activeMenu: 'receiptConfirmation'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/receiptConfirmation/receiptConfirmationList'], resolve);
    }
  }, {
    path: '/receiptConfirmationDetail',
    hidden: true,
    meta: {
      title: '收货确认单详情',
      key_name: 'receipt_confirmation_detail',
      noCache: false,
      activeMenu: 'receiptConfirmation'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/receiptConfirmation/receiptConfirmationDetail'], resolve);
    }
  }, {
    path: '/serviceOrderList',
    hidden: true,
    meta: {
      title: '服务订单',
      key_name: 'service_order_list',
      noCache: false,
      activeMenu: 'serviceOrderDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceOrder/serviceOrderList'], resolve);
    }
  }, {
    path: '/serviceOrderDetail',
    hidden: true,
    meta: {
      title: '服务订单详情',
      key_name: 'service_order_details',
      noCache: false,
      activeMenu: 'serviceOrderDetail'
    },
    component: function component(resolve) {
      return require(['@/views/toOutside/serviceOrder/serviceOrderDetail'], resolve);
    }
  }]
}, // {
//   path: '/inquiryToSupplierList',
//   hidden: true,
//   meta: { title: '供应商询价列表', noCache: false },
//   component: (resolve) => require(['@/views/toOutside/inquiry/inquiryToSupplierList'], resolve),
// },
// {
//   path: '/inquiryToSupplierPerfect',
//   hidden: true,
//   meta: { title: '供应商完善信息', noCache: false },
//   component: (resolve) => require(['@/views/toOutside/supplierPerfect'], resolve),
// },
{
  path: '/PDFToExcel',
  component: function component(resolve) {
    return require(['@/views/PDFToExcel'], resolve);
  },
  name: 'PDFToExcel',
  hidden: true,
  meta: {
    title: '',
    noCache: false
  }
}, {
  path: '/inquiryOverdue',
  hidden: true,
  meta: {
    title: '询价过期',
    noCache: false
  },
  component: function component(resolve) {
    return require(['@/views/toOutside/inquiryOverdue'], resolve);
  }
}];
exports.constantRouterMap = constantRouterMap;

var _default = new _vueRouter.default({
  mode: 'hash',
  // mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});

exports.default = _default;