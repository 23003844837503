"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.messages = exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _zhCN = _interopRequireDefault(require("./zh-CN"));

var _enUS = _interopRequireDefault(require("./en-US"));

var _zh_CN_VXE = _interopRequireDefault(require("./zh_CN_VXE"));

var _enUSVXE = _interopRequireDefault(require("./en-US-VXE"));

_vue.default.use(_vueI18n.default);

var messages = {
  'zh-CN': (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({
    '_lang': '简体中文'
  }, _zhCN.default), ELEMENT.lang.zhCN), _zh_CN_VXE.default),
  'en-US': (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({
    '_lang': 'English'
  }, _enUS.default), ELEMENT.lang.en), _enUSVXE.default)
};
exports.messages = messages;

var _default = new _vueI18n.default({
  locale: _jsCookie.default.get('language') || 'zh-CN',
  messages: messages
});

exports.default = _default;