var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog,
            "close-on-click-modal": false,
            "before-close": _vm.cancel,
            title: _vm.$setLang("change_password"),
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
            close: _vm.cancel,
          },
        },
        [
          !_vm.user.pwdReset
            ? _c(
                "div",
                [
                  _c("svg-icon", {
                    staticStyle: { "margin-right": "4px" },
                    attrs: { "icon-class": "error-hint" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticStyle: { color: "646a73", "font-size": "12px" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$setLang("change_password_again")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "label-width": _vm.$i18n.locale === "zh-CN" ? "78px" : "125px",
              },
            },
            [
              _vm.user.pwdReset
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$setLang("old_password"),
                        prop: "oldPass",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "password", "auto-complete": "on" },
                        model: {
                          value: _vm.form.oldPass,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "oldPass", $$v)
                          },
                          expression: "form.oldPass",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$setLang("new_password"),
                    prop: "newPass",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$setLang("password_length_incorrect"),
                      type: "password",
                      "auto-complete": "on",
                    },
                    model: {
                      value: _vm.form.newPass,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newPass", $$v)
                      },
                      expression: "form.newPass",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$setLang("confirm_password"),
                    prop: "confirmPass",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "password", "auto-complete": "on" },
                    model: {
                      value: _vm.form.confirmPass,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "confirmPass", $$v)
                      },
                      expression: "form.confirmPass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [
                _vm._v(_vm._s(_vm.$setLang("cancel"))),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(_vm._s(_vm.$setLang("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }