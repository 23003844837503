"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.deleteAction = deleteAction;
exports.deleteActionP = deleteActionP;
exports.getAction = getAction;
exports.getActionExport = getActionExport;
exports.getImage = getImage;
exports.getPageListAction = getPageListAction;
exports.httpAction = httpAction;
exports.postAction = postAction;
exports.postActionForBlob = postActionForBlob;
exports.postActionForBlobSup = postActionForBlobSup;
exports.putAction = putAction;

require("core-js/modules/es6.regexp.to-string");

var _axios = _interopRequireDefault(require("axios"));

var _routers = _interopRequireDefault(require("@/router/routers"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("../store"));

var _auth = require("@/utils/auth");

var _settings = _interopRequireDefault(require("@/settings"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

// 创建axios实例
var service = _axios.default.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: _settings.default.timeout // 请求超时时间

}); // request拦截器


service.interceptors.request.use(function (config) {
  config.headers['Authorization'] = '';

  switch (_store.default.state.user.terminalType) {
    case 1:
      if ((0, _auth.getToken)()) {
        config.headers['Authorization'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
      }

      break;

    case 2:
      if ((0, _auth.getCustomerToken)()) {
        config.headers['Authorization'] = (0, _auth.getCustomerToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
      }

      break;

    case 3:
      if ((0, _auth.getSupplierToken)()) {
        config.headers['Authorization'] = (0, _auth.getSupplierToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
      }

      break;
  }

  config.headers['Content-Type'] = 'application/json';
  config.headers['Accept-Language'] = _jsCookie.default.get('language');
  return config;
}, function (error) {
  Promise.reject(error);
}); // response 拦截器

service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  // 兼容blob下载出错json提示
  if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
    var reader = new FileReader();
    reader.readAsText(error.response.data.data, 'utf-8');

    reader.onload = function (e) {
      var errorMsg = JSON.parse(reader.result).message;

      _elementUi.Notification.error({
        title: errorMsg,
        duration: 5000
      });
    };
  } else {
    var code = 0;

    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        _elementUi.Notification.error({
          title: '网络请求超时',
          duration: 5000
        });

        return Promise.reject(error);
      }
    }

    if (code) {
      if (code === 401 || code === 406 || code === 2000) {
        _jsCookie.default.set(_settings.default.TokenKey, '');

        switch (_store.default.state.user.terminalType) {
          case 1:
            if (_store.default.state.tagsView.visitedViews.length) {
              localStorage.setItem("tokenInvalid", true);
            }

            break;

          case 2:
            localStorage.setItem("tokenInvalidCus", true);
            break;

          case 3:
            localStorage.setItem("tokenInvalidSup", true);
            break;
        }

        _store.default.dispatch("LogOut").then(function () {
          location.reload();
        }); // store.dispatch('LogOut')
        // setTimeout(() => {
        // }, 1000);
        // if(store.state.user.terminalType == 1)

      } //  else if (code == 408) {
      //   const errorMsg = error.response.data.message
      //   if (errorMsg !== undefined) {
      //     if (Cookies.get('language') == 'zh-CN') {
      //       Message.error('抱歉，您没有访问权限！请联系管理员开通访问权限。')
      //     } else {
      //       Message.error('You do not have permission to access this page temporarily! please contact the administrator to configure it before operation.')
      //     }
      //   }
      //   return error.response.data
      // } 
      else if (code === 403) {
        _routers.default.push({
          path: '/401'
        });
      } else if (code === 400) {
        var errorMsg = error.response.data.message;

        if (errorMsg !== undefined) {
          _elementUi.Message.error(errorMsg);
        } // return error.response.data

      } else if (code === 500) {
        var _errorMsg = error.response.data.message;

        if (_errorMsg !== undefined) {// Message.error(errorMsg)
        }

        return error.response.data;
      } // else if (code === 406) {
      //   //无权限
      //   // const errorMsg = error.response.data.message
      //   // if (errorMsg !== undefined) {
      //   //   Notification.error({
      //   //     title: errorMsg,
      //   //     duration: 5000
      //   //   })
      //   // }
      //   return error.response.data
      // }
      else {
        var _errorMsg2 = error.response.data.message;

        if (_errorMsg2 !== undefined) {
          _elementUi.Message.error(_errorMsg2);
        }
      }
    } else {
      _elementUi.Notification.error({
        title: '接口请求失败',
        duration: 5000
      });
    }
  }

  return Promise.reject(error);
});
/**
 * 请求方法
 */
// get

function getAction(url, parameter, headers) {
  return service({
    url: url,
    method: 'get',
    params: parameter,
    headers: headers ? headers : {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  });
} //get default list


function getPageListAction(url, parameter, headers) {
  var defalutListParams = {
    currentPage: 1,
    'orderFields[0].direction': 'ASC',
    'orderFields[0].fieldName': 'ID',
    pageSize: 1e3
  };
  return service({
    url: url,
    method: 'get',
    params: Object.assign(defalutListParams, parameter),
    headers: headers ? headers : {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  });
} // export


function getActionExport(url, parameter) {
  var url_s;

  if (parameter.type) {
    url_s = "id=".concat(parameter.id, "&type=").concat(parameter.type);
  } else {
    url_s = "id=".concat(parameter);
  }

  _axios.default.get("".concat(window.SITE_CONFIG["apiURL"]).concat(url, "?").concat(url_s), {
    responseType: "arraybuffer",
    // 或者responseType: 'blob'
    xsrfHeaderName: "Authorization",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + _jsCookie.default.get("access_token")
    }
  }).then(function (res) {
    var blob = new Blob([res.data], {
      type: "application/vnd.ms-excel"
    });
    var objectUrl = URL.createObjectURL(blob);
    window.location.href = objectUrl;
  }).catch(function (err) {
    console.log(err);
  });
}
/**
 * 
 * @param url 图片地址
 */


function getImage(url) {
  return (0, _axios.default)({
    method: "GET",
    url: process.env.VUE_APP_PIC_API + url,
    responseType: "blob"
  });
} // post


function postAction(url, parameter) {
  return service({
    url: url,
    method: 'post',
    data: parameter,
    headers: {
      'Content-type': 'application/json'
    }
  });
}

function postActionForBlob(url, parameter) {
  console.log("????????", url);
  return service({
    url: url,
    method: 'post',
    data: parameter,
    headers: {
      'Authorization': _jsCookie.default.get()["ADMIN-TOEKN"]
    },
    responseType: "blob"
  });
}

function postActionForBlobSup(url, parameter) {
  return service({
    url: url,
    method: 'post',
    data: parameter,
    headers: {
      'Authorization': _jsCookie.default.get()["supplierTokenKey"]
    },
    responseType: "blob"
  });
} // post method= {post | put}


function httpAction(url, parameter, method) {
  return service({
    url: url,
    method: method,
    data: parameter
  });
} // put


function putAction(url, parameter) {
  return service({
    url: url,
    method: 'put',
    data: parameter
  });
} // deleteAction


function deleteAction(url, parameter) {
  return service.delete(url, {
    data: parameter
  }); // return http({
  //   url: url,
  //   method: 'delete',
  //   params: parameter,
  //   headers:{'Content-type':'application/json'}
  // })
}

function deleteActionP(url, parameter) {
  // return service.delete(url, { data: parameter })
  return service({
    url: url,
    method: 'delete',
    params: parameter,
    headers: {
      'Content-type': 'application/json'
    }
  });
}

var _default = service;
exports.default = _default;