"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CommonModal = _interopRequireDefault(require("./CommonModal.vue"));

var _default = {
  install: function install(Vue) {
    // console.log('自定义的插件~')
    // 在自定义的插件中注册为全局组件
    Vue.component('AnnoCommonModal', _CommonModal.default); // Vue.component('组件名2',yyy)
    // // 也可以往Vue的原型对象上添加属性或者方法，名字可以自定义
    // // 在其他.vue结尾的文件中，可以通过this，访问自己添加的属性和方法
    // Vue.prototype.num = 10
    // Vue.prototype.$sayHi = () => {
    // 	alert('Hi~')
    // }
  }
};
exports.default = _default;