"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/PROD-\u660E\u6770 (\u524D\u7AEF\u6784\u5EFA)/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _path = _interopRequireDefault(require("path"));

var _validate = require("@/utils/validate");

var _Item = _interopRequireDefault(require("./Item"));

var _Link = _interopRequireDefault(require("./Link"));

var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SidebarItem",
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  computed: {
    newItem: function newItem() {
      var item = this.item;

      if (this.item.path === "/") {
        console.log(item);
        item.children[0].meta.title = this.$setLang("home");
      }

      return item;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }
    }
  },
  methods: {
    bfsBYJs: function bfsBYJs(node) {
      var nodes = [];
      var stack = [];

      if (node) {
        stack.push(node);

        while (stack.length) {
          var item = stack.shift();
          var children = item.children; // console.log(children, "child");

          if (children) {
            for (var i = 0; i < children.length; i++) {
              stack.push(children[i]);

              if (children[i].meta) {
                nodes.push(children[i].meta.activeMenu);
              }
            }
          }
        }
      }

      return nodes;
    },
    //控制模块页
    handleMenuUN: function handleMenuUN(val) {
      // console.log(val, "handleMenuUN");
      if (val) {
        var arr = this.bfsBYJs(val);
        arr = (0, _toConsumableArray2.default)(new Set(arr.filter(function (it) {
          return it !== null;
        }))); // console.log(arr, "val");

        return arr.includes(this.activeMenu);
      }

      return true;
    },
    //控制内部菜单
    handleMenu: function handleMenu(val) {
      if (this.activeMenu == val) {
        return true;
      } else {
        return false;
      }

      return true;
    },
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;

      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: "",
          noShowingChildren: true
        });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }

      return _path.default.resolve(this.basePath, routePath);
    }
  }
};
exports.default = _default;